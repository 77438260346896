/** @format */

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { isAuth } from "../../Helpers/FrontendHelper";
import useOnlineHelper from "../../Helpers/useOnlineHelper";
import MyOrder from "../MyOrder/MyOrder";
import PhoneNumberForm, { OTPForm } from "./ValidatePhoneNumberForm";

export const sectionConstants = {
  cartItmes: "CART_ITEMS",
  phonerNumberForm: "PHONER_NUMBER_FORM",
  otpForm: "OTP_FORM",
};

const AddToCart = ({ myOrderComponent }) => {
  const navigate = useNavigate();
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const homePageData = useSelector(
    (state) => state.storeReducer.unUpdatedHomePageData
  );
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const googleLoginLoading = useSelector(
    (state) => state.authentication.googleLoginLoading
  );
  const storesCartData = useSelector(
    (state) => state.StateManager.storesCartData
  );

  const {
    isHomePage,
    isCartEmpty,
    deliveryMethodFromParam,
    tableIdFromQuery,
    isInCheckoutRoute,
    isInGuestCheckoutRoute,
    tableNameFromQuery,
    navigateToHomePage,
    navigateToCheckoutPage,
    navigateToGuestCheckoutPage,
    isStoreClosed,
  } = useOnlineHelper();
  const paramValue = useParams();

  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [activeDeliveryMethod, setActiveDeliveryMethod] = useState();
  const [sectionToShow, setSectionToShow] = useState(
    sectionConstants.cartItmes
  );

  useEffect(() => {
    if (tableIdFromQuery) {
      setDeliveryMethods(
        homePageData?.deliveryMethod?.filter(
          (item) => item.deliveryMethodValue == "3"
        )
      );
      setActiveDeliveryMethod(
        homePageData?.deliveryMethod?.find(
          (item) => item.deliveryMethodValue == "3"
        )?.deliveryMethodValue
      );
    } else {
      setDeliveryMethods(
        homePageData?.deliveryMethod?.filter(
          (item) => item.deliveryMethodValue != "3"
        )
      );
      if (deliveryMethodFromParam) {
        setActiveDeliveryMethod(deliveryMethodFromParam);
      } else {
        setActiveDeliveryMethod(
          homePageData?.deliveryMethod?.[0]?.deliveryMethodValue
        );
      }
    }
  }, [tableIdFromQuery, deliveryMethodFromParam, homePageData]);

  useEffect(() => {
    if (isInGuestCheckoutRoute && activeDeliveryMethod !== undefined) {
      // console.log('navigation active')
      navigateToGuestCheckoutPage(activeDeliveryMethod);
    } else if (isInCheckoutRoute && activeDeliveryMethod !== undefined) {
      navigateToCheckoutPage(activeDeliveryMethod);
    }
  }, [activeDeliveryMethod]);

  const navigateAfterPhoneValidationGuestCheckout = () => {
    navigateToGuestCheckoutPage(activeDeliveryMethod);
  };

  const renderSection = () => {
    switch (sectionToShow) {
      case sectionConstants.cartItmes:
        return (
          <div class="cart-detail-box bg-white">
            <div class="del_address mb-0">
              <div class="bg-white">
                <h5
                  id="offcanvascartLabel"
                  class="fw-bold pt-3 ps-3 mb-2 d-none d-md-block"
                >
                  Place Order
                </h5>
                <ul class="nav nav-tabs nav-fill " id="myTab" role="tablist">
                  {deliveryMethods?.map((item) => {
                    return (
                      <li class="nav-item" role="presentation" key={item.id}>
                        <button
                          className={
                            item.deliveryMethodValue == activeDeliveryMethod
                              ? "nav-link active"
                              : "nav-link"
                          }
                          id="faq_tab_1-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#faq_tab_1"
                          type="button"
                          role="tab"
                          aria-controls="faq_tab_1"
                          aria-selected="true"
                          onClick={() => {
                            setActiveDeliveryMethod(item.deliveryMethodValue);
                          }}
                        >
                          <div class="d-flex flex-column lh-lg">
                            {" "}
                            <i
                              class={`${
                                item.deliveryMethodValue == 1
                                  ? "fas fa-store"
                                  : item.deliveryMethodValue == 2
                                  ? "fas fa-truck"
                                  : "fas fa-utensils"
                              }`}
                            />{" "}
                            <span>{item.name}</span>{" "}
                          </div>
                        </button>{" "}
                      </li>
                    );
                  })}
                </ul>

                {activeDeliveryMethod == "1" && (
                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade active show "
                      id="faq_tab_1"
                      role="tabpanel"
                      aria-labelledby="faq_tab_1-tab"
                    >
                      <div class="row">
                        <div class="col-md-12 mt-3">
                          <div class="card rounded-0 border-0">
                            <div class="card-body py-0">
                              <div class="pickaddress fw-bold">
                                <p class="text-dark fw-bold">
                                  <i class="fas fa-map-marker-alt" />{" "}
                                  {homePageData?.storeDetails?.storeName}
                                  <br />
                                  <span class="pickloc lh-1">
                                    <small>
                                      {homePageData?.storeDetails?.address}
                                    </small>
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {myOrderComponent ? (
              myOrderComponent
            ) : (
              <MyOrder deliveryMethod={activeDeliveryMethod} />
            )}
          </div>
        );
      case sectionConstants.phonerNumberForm:
        return <PhoneNumberForm setSectionToShow={setSectionToShow} />;
      case sectionConstants.otpForm:
        return (
          <OTPForm
            setSectionToShow={setSectionToShow}
            navigateAfterPhoneValidationGuestCheckout={
              navigateAfterPhoneValidationGuestCheckout
            }
          />
        );
      default:
        break;
    }
  };

  return (
    <div
      className={`offcanvas offcanvas-end offcanvas-md-none  maincart border-0 offcanvas-collapse `}
      tabIndex={-1}
      id="shoppingCart"
      aria-labelledby="shoppingCartLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="offcanvas-header d-lg-none">
        <h5 className="offcanvas-title fw-bold" id="shoppingCartLabel">
          Place Order
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body p-0 overflow-x-hidden">
        {renderSection()}
      </div>
      {sectionToShow == sectionConstants.cartItmes && (
        <div className="offcanvas-header flex-column align-items-start p-0 fixed-checkout-button">
          <div class=" card-footer padding-15 bg-white border-0 w-100 ">
            {isHomePage && !isStoreClosed && (
              <div class="downbottom d-flex mt-2 w-100 gap-3">
                <a
                  className={
                    "btn-first green-btn text-custom-white fw-500 w-100 py-2"
                  }
                  onClick={() => {
                    if (!isCartEmpty) {
                      if (isAuth(storeId)) {
                        navigateToCheckoutPage(activeDeliveryMethod);
                      } else {
                        if (
                          JSON.parse(
                            localStorage.getItem("isPhoneNumberValidated")
                          ) ||
                          tableIdFromQuery
                        ) {
                          navigateAfterPhoneValidationGuestCheckout();
                          return;
                        } else {
                          setSectionToShow(sectionConstants.phonerNumberForm);
                        }
                      }
                    }
                  }}
                  style={
                    isCartEmpty ? { pointerEvents: "none", opacity: 0.5 } : {}
                  }
                >
                  Proceed to Checkout
                </a>
              </div>
            )}
            {!isHomePage && !isStoreClosed && (
              <div class=" d-flex w-100 gap-3">
                <a
                  data-bs-dismiss="offcanvas"
                  className={
                    "btn-first green-btn add-items-button  fw-500 w-100 py-2"
                  }
                  onClick={() => {
                    navigateToHomePage();
                  }}
                >
                  Add items
                </a>
              </div>
            )}
            {isStoreClosed && (
              <div class=" d-flex w-100 gap-3">
                <a
                  data-bs-dismiss="offcanvas"
                  className={
                    "btn-first green-btn add-items-button  fw-500 w-100 py-2"
                  }
                  style={isCartEmpty ? { pointerEvents: "none" } : {}}
                >
                  Store Closed
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddToCart;
